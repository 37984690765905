import React from 'react'
import Layout from '../components/layout'

export default function Videolistpage() {
  return (
      <Layout>
        <h2 className='font-semibold my-4'>VIDEOS</h2>
        <div>
            <iframe className="w-full mb-4" height="300" src="https://www.youtube.com/embed/PhwDyyo7GCY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe className="w-full mb-4" height="300" src="https://www.youtube.com/embed/kdXhbZ-QvMo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe className="w-full mb-4" height="300" src="https://www.youtube.com/embed/Vz5d37gSuHk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe className="w-full mb-4" height="300" src="https://www.youtube.com/embed/AgvUZyk_lV4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe className="w-full mb-4" height="300" src="https://www.youtube.com/embed/US0QVMWFiwQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe className="w-full mb-4" height="300" src="https://www.youtube.com/embed/zlx0WSjLR3g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </Layout>
  )
}


// export default function VideolistpageRender() {
//   return (
//     <>
//       <h3 className="block my-4 text-1xl bg-slate-900 text-white font-semibold p-2">Transfer Center</h3>
//       <Videolistpage />
//     </>
//   );
// }